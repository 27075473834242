<template>
  <v-main align="center">
    <v-container fluid>
      <v-row justify="center">
        <v-col
          cols="12"
          sm="12"
          md="8"
          v-if="errorMessage == null && this.fetched == true"
        >
          <v-card
            v-for="(post, i) in news"
            :key="i"
            class="elevation-12 mb-5 py-3"
            align="left"
          >
            <v-img
              v-if="post.imagePath != null"
              :src="'https://stok.iot-ab.se/' + post.imagePath"
              max-height="450px"
              class="mx-5"
            />
            <v-card-title> {{ post.title }} </v-card-title>
            <v-card-subtitle>
              {{ humanDate(post.date, "yyyy-MM-dd") }}
            </v-card-subtitle>
            <v-card-text
              class="text-body-1"
              v-html="post.content"
            ></v-card-text>
          </v-card>
          <!-- TODO: ADD PAGINATION -->
          <v-btn outlined color="primary" @click="nextPage"
            ><v-icon>mdi-refresh</v-icon> {{ $t("common.getMorePages") }}</v-btn
          >
        </v-col>
        <v-col cols="12" sm="12" md="8" v-else>
          <v-card class="elevation-12 mb-5 py-3" align="left">
            <v-card-text
              style="
                width: 90%;
                margin: 0 auto;
                border-radius: 10px;
                background-color: rgb(238, 85, 85);
                color: white;
              "
              >{{ this.errorMessage }}</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "News",

  data() {
    return {
      page: 0,
      news: [],
      errorMessage: null,
      fetched: false,
    };
  },
  computed: {},
  methods: {
    async setNews() {
      let fetchedNews = await fetch(
        `https://stok.iot-ab.se/api/news/all/${this.page}`
      )
        .then((response) => response.json())
        .then((d) => d);

      if (fetchedNews == undefined) {
        this.errorMessage = this.$t("news.cannotGetNews");
      } else if (fetchedNews.length > 0) {
        this.news = this.news.concat(fetchedNews);
        this.fetched = true;
      }
    },

    async nextPage() {
      this.page = this.page + 1;
      await this.setNews();
    },
  },
  async created() {
    this.errorMessage = null;
    await this.setNews();
  },
};
</script>

<style>
</style>